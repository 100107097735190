<template>
  <header>
    <router-link to="/"
      ><div>
        <img
          src="/img/dc-logo-saude.png"
          alt="Logo da Secretaria municipal de Saúde e Defesa Civil da Prefeitura de Duque de Caxias."
          class="w-1/2"
        /></div
    ></router-link>
  </header>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
