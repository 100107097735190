<template>
  <footer>
    <div class="border-t-4 border-yellow-400" style="background-color: #031326">
      <div class="max-w-2xl mx-auto text-white py-10">
        <div>
          <center>
            <img
              src="/img/dc-logo-saude.png"
              alt="Logo da Prefeitura de Duque de Caxias"
              class="mb-3 w-1/2"
            />
          </center>

          <br />
          <p class="border-2 p-5">
            Desenvolvido pela: Subsecretaria de Tecnologia
          </p>

          <div
            class="mt-10 flex flex-col md:flex-row md:justify-between items-center text-sm text-gray-400"
          >
            <p class="order-2 md:order-1 mt-4 md:mt-0">
              &copy; Prefeitura Municipal de Duque de Caxias, 2021.
            </p>
            <div class="order-1 md:order-2">
              <span class="font-bold">Portal da:</span> <br />
              <a
                href="https://saude.duquedecaxias.rj.gov.br/"
                target="_blank"
                class="px-2 hover:text-white"
                >Saúde</a
              >
              <a
                href="https://transparencia.duquedecaxias.rj.gov.br/"
                target="_blank"
                class="px-2 border-l border-gray-500 hover:text-white"
                >Transparência</a
              >
              <a
                href="https://duquedecaxias.rj.gov.br/"
                target="_blank"
                class="px-2 border-l border-gray-500 hover:text-white"
                >Prefeitura</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
