<template>
  <div id="conoscoId">
    <div
      class="bg-cover bg-center h-auto text-white py-24 px-10 object-fill"
      style="
        background-color: #031326;
        background-image: url('/img/banner.png');
      "
    >
      <center>
        <div class="md:w-10/12">
          <p class="text-xl mb-2">
            Tenha acesso a um <b>atendimento</b> com uma <b>equipe de saúde</b>,
            pelo <span class="text-green-500 font-bold">WhatsApp</span>, através
            de telemedicina, evitando <b>filas e aglomeração desnecessárias</b>.
          </p>

          <p class="text-md leading-none">
            Tudo isso sem abrir mão da sua saúde.
          </p>

          <br />
          <div class="animate-bounce my-5">
            <a
              href="https://bit.ly/telemedicinadcrj"
              target="_blank"
              class="rounded-2xl p-4 shadow-xl font-button"
              style="background-color: #ff751a"
              >FALE COM PROFISSIONAL DE SAÚDE</a
            >
          </div>

          <br />

          <p class="font-2xl text-white leading-none uppercase warning-hover">
            <strong>Atenção</strong>: programa destinado à pessoas com sintomas
            leves. Não substitui o serviço de emergência presencial.
          </p>
      
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-10 gap-4">
          <div
            class="hidden sm:block col-span-2 sm:col-span-1 py-10 rounded-2xl bg-cover shadow-2xl"
            style="background-color: #ff751a"
          >
            <a href="https://bit.ly/telemedicinadcrj" target="_blank"
              ><img src="/img/saudeonline.png" class="hover-image1"
            /></a>
          </div>
          <div
            class="col-span-2 sm:col-span-1 py-10 md:py-16 rounded-2xl shadow-2xl"
            style="
              background-image: url('/img/phone-white.png');
              background-size: 70%;
              background-color: #0ba2dd;
            "
          >
            <center class="m-auto py-12">
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 40 40"
                  style="fill: #000000"
                >
                  <path
                    fill="#f2faff"
                    d="M4.221,29.298l-0.104-0.181c-1.608-2.786-2.459-5.969-2.458-9.205C1.663,9.76,9.926,1.5,20.078,1.5	c4.926,0.002,9.553,1.919,13.03,5.399c3.477,3.48,5.392,8.107,5.392,13.028c-0.005,10.153-8.268,18.414-18.42,18.414	c-3.082-0.002-6.126-0.776-8.811-2.24l-0.174-0.096l-9.385,2.46L4.221,29.298z"
                  ></path>
                  <path
                    fill="#788b9c"
                    d="M20.078,2L20.078,2c4.791,0.001,9.293,1.867,12.676,5.253C36.137,10.639,38,15.14,38,19.927	c-0.005,9.878-8.043,17.914-17.927,17.914c-2.991-0.001-5.952-0.755-8.564-2.18l-0.349-0.19l-0.384,0.101l-8.354,2.19l2.226-8.131	l0.11-0.403L4.55,28.867c-1.566-2.711-2.393-5.808-2.391-8.955C2.163,10.036,10.202,2,20.078,2 M20.078,1	C9.651,1,1.163,9.485,1.158,19.912c-0.002,3.333,0.869,6.588,2.525,9.455L1,39.169l10.03-2.63c2.763,1.507,5.875,2.3,9.042,2.302	h0.008c10.427,0,18.915-8.485,18.92-18.914c0-5.054-1.966-9.807-5.538-13.382C29.89,2.971,25.14,1.002,20.078,1L20.078,1z"
                  ></path>
                  <path
                    fill="#79ba7e"
                    d="M19.995,35c-2.504-0.001-4.982-0.632-7.166-1.823l-1.433-0.782l-1.579,0.414l-3.241,0.85l0.83-3.03	l0.453-1.656L7,27.485c-1.309-2.267-2.001-4.858-2-7.492C5.004,11.726,11.732,5.001,19.998,5c4.011,0.001,7.779,1.563,10.61,4.397	C33.441,12.231,35,15.999,35,20.005C34.996,28.273,28.268,35,19.995,35z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M28.28,23.688c-0.45-0.224-2.66-1.313-3.071-1.462c-0.413-0.151-0.712-0.224-1.012,0.224	c-0.3,0.45-1.161,1.462-1.423,1.761c-0.262,0.3-0.524,0.337-0.974,0.113c-0.45-0.224-1.899-0.7-3.615-2.231	c-1.337-1.191-2.239-2.663-2.501-3.113c-0.262-0.45-0.029-0.693,0.197-0.917c0.202-0.202,0.45-0.525,0.674-0.787	c0.224-0.262,0.3-0.45,0.45-0.75c0.151-0.3,0.075-0.563-0.038-0.787s-1.012-2.437-1.387-3.336c-0.364-0.876-0.736-0.757-1.012-0.771	c-0.262-0.014-0.562-0.015-0.861-0.015c-0.3,0-0.787,0.113-1.198,0.563s-1.573,1.537-1.573,3.749s1.611,4.35,1.835,4.649	c0.224,0.3,3.169,4.839,7.68,6.786c1.072,0.462,1.911,0.739,2.562,0.947c1.076,0.342,2.057,0.294,2.832,0.178	c0.864-0.129,2.66-1.087,3.034-2.136c0.375-1.049,0.375-1.95,0.262-2.136C29.03,24.025,28.731,23.912,28.28,23.688z"
                  ></path>
                </svg>
                WhatsApp</span
              >

              <br />

              <a
                href="https://bit.ly/telemedicinadcrj"
                target="_blank"
                class="font-bold hover-font"
                ><span class="text-sm">21</span> 97299-4203</a
              >

              <br />

              <span class="uppercase">Atendimento 24 Horas</span>
            </center>
          </div>
          <div
            class="col-span-2 md:col-span-1 py-10 md:py-24 rounded-2xl shadow-2xl"
            style="background-color: #ffcc00"
          >
            <a href="https://bit.ly/telemedicinadcrj" target="_blank"
              ><img
                src="/img/qr-code.png"
                alt="Qrcode do Saúde Online"
                style="
                  background-color: #f9f9f9;
                  box-shadow: 8px 8px 0px #0ba2dd;
                "
                class="rounded hover-image2"
            /></a>
          </div>
        </div>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  name: "QRCODE",
};
</script>

<style scoped>
.warning-hover {
  color: #ffcc00;
  transition: 0.5s;
}

.warning-hover2 {
  background-color: #a52a2a;
  transition: 0.5s;
}

.hover-font {
  font-size: 1.4rem;
  transition: 0.5s;
}

.hover-font:hover {
  font-size: 1.6rem;
  transition: 0.5s;
}

.font-button {
  font-size: 1rem;
}

@media (max-width: 395px) {
  .font-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 330px) {
  .font-button {
    font-size: 0.6rem;
  }
}

@media (max-width: 290px) {
  .font-button {
    font-size: 0.5rem;
  }
}

.hover-image1 {
  transition: 0.5s;
  width: 14rem;
}

.hover-image1:hover {
  transition: all 0.5s;
  width: 14.5rem;
}

.hover-image2 {
  transition: 0.5s;
  width: 8rem;
}

.hover-image2:hover {
  transition: all 0.5s;
  width: 8.5rem;
}
</style>
