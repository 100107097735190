<template>
  <div class="home">
    <Banner />
    <section>
      <Atendimento />
      <Atendimento2 />
      <QRCODE />
      <Footer />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Banner.vue";
import Atendimento from "@/components/Atendimento.vue";
import Atendimento2 from "@/components/Atendimento2.vue";
import QRCODE from "@/components/QRCODE.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Banner,
    Atendimento,
    Atendimento2,
    QRCODE,
    Footer,
  },
};
</script>
