<template>
  <div>
    <div
      class="grid grid-cols-1 sm:grid-cols-2"
      style="
        background-color: #031326;
        background-image: url('/img/phone.png');
        background-repeat: no-repeat;
        background-size: 15%;
        background-position-y: 50%;
      "
    >
      <div
        class="sm:text-justify text-white m-auto text-2xl sm:text-xl"
        style=""
      >
        <div class="px-8 py-10">
          <span class="font-bold">Atendimento</span> <br />
          <span class="font-bold">médico</span> sem sair de casa. <br />
          Conheça o <br />
          <span
            class="md:text-3xl sm:text-2xl padding-animation font-extrabold"
            style="color: #ffcc00"
            >SAÚDE ONLINE</span
          >
          <br />
          <span class="font-bold">Duque de Caxias</span>
        </div>
      </div>

      <div
        class="bg-blue-900 content-center"
        style="
          background-image: url('/img/phone-white.png');
          background-size: 50%;
        "
      >
        <img
          src="/img/saudeonline.png"
          alt="Saúde Online"
          class="w-1/3 sm:w-2/4 xl:w-1/3 m-auto p-0 padding-animation hover:origin-center scale-125"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Atendimento",
};
</script>

<style scoped>
.padding-animation:hover {
  padding: 5px;
  transition: 0.4s;
}
</style>
