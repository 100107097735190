<template>
  <div
    class="bg-cover bg-center h-auto text-white py-24 px-10 object-fill border-b-4 border-yellow-400"
    style="background-image: url('/img/banner.png')"
  >
    <div class="text-center">
      <p class="font-bold text-sm uppercase">Conhece o</p>
      <p class="text-3xl font-bold" style="color: #0ba2dd">SAÚDE ONLINE?</p>
      <p class="text-2xl mb-10 leading-none">
        O aplicativo que vai facilitar sua saúde
      </p>
      <div class="animate-bounce">
        <a
          href="#conoscoId"
          class="rounded-2xl p-4 shadow-xl font-button"
          style="background-color: #ff751a"
          >FALE COM PROFISSIONAL DE SAÚDE</a
        >

        
      </div>

       
      

      <br />
      <p
            class="font-2xl text-white leading-none uppercase warning-hover2 p-4 mt-2 rounded-xl"
          >
            "Por se tratar de um projeto piloto e para garantir o maior controle
            da Secretaria de Saúde, a primeira fase do Saúde Online Duque de
            Caxias terá início em Xerém, no quarto distrito. Há previsão de
            expansão da iniciativa para outras regiões da cidade a medida em que
            o programa estiver consolidado."
          </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style scoped>
.font-button {
  font-size: 1rem;
}

@media (max-width: 395px) {
  .font-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 330px) {
  .font-button {
    font-size: 0.6rem;
  }
}

@media (max-width: 290px) {
  .font-button {
    font-size: 0.5rem;
  }
}

.warning-hover {
  color: #ffcc00;
  transition: 0.5s;
}

.warning-hover2 {
  background-color: #a52a2a;
  transition: 0.5s;
}
</style>
