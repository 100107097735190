<template>
  <div>
    <div class="grid grid-cols-1 sm:grid-cols-2">
      <div
        class="text-justify py-10 x text-white bg-blue-900"
        style="
          background-image: url('/img/phone-white.png');
          background-size: 50%;
        "
      >
        <ul class="mt-10">
          <li
            class="mb-5 py-4 animation-padding rounded-r-2xl mr-10 shadow-xl"
            style="
              background-color: #ffcc00;
              text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
            "
          >
            <span class="font-bold">Sem</span> fila
          </li>
          <li
            class="mb-5 py-4 animation-padding rounded-r-2xl mr-10 shadow-xl"
            style="
              background-color: #ff751a;
              text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
            "
          >
            <span class="font-bold">Sem</span> deslocamento
          </li>
          <li
            class="py-4 animation-padding rounded-r-2xl mr-10 shadow-xl"
            style="
              background-color: #0ba2dd;
              text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
            "
          >
            <span class="font-bold">Sem</span> aglomeração
          </li>
        </ul>
      </div>

      <div
        class=""
        style="
          background-color: #031326;
          background-image: url('/img/phone.png');
          background-repeat: no-repeat;
          background-size: 50%;
          background-position-y: 50%;
        "
      >
        <div class="m-auto px-8 py-24 mx-20">
          <h2 class="text-2xl uppercase font-bold" style="color: white">
            Atendimento Online
            <span class="text-3xl underline decoration-4" style="color: #ffcc00"
              >24 horas</span
            >
            por dia
          </h2>
          <p style="font-size: 1.2rem; color: white">
            Acesse o <span class="font-bold">QR Code</span> e fale com <br />
            uma equipe de profissionais de saúde de onde você estiver!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Atendimento2",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.animation-padding {
  padding-left: 3rem;
  transition: 0.6s;
}

.animation-padding:hover {
  padding-left: 8rem;
  transition: 0.6s;
}
</style>
